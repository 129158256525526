<template>
  <fab
    class="noprint"
    :position="position"
    :bg-color="background_color"
    :actions="fab_actions"
    title="Navegar a"
    @goToEgressProfile="goToEgressProfile"
    @goToMatrix1="goToMatrix1"
    @goToMatrix2="goToMatrix2"
    @goToMatrix3="goToMatrix3"
    @goToMatrix4="goToMatrix4"
    @goToStudyPlan="goToStudyPlan"
    @goToCurricularMesh="goToCurricularMesh"
  ></fab>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
export default {
  name: "MeshCustomFab",
  components: {
    fab: () => import("vue-fab"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    show_egress_profile: {
      type: Boolean,
      default: true,
    },
    show_matrix1: {
      type: Boolean,
      default: true,
    },
    show_matrix2: {
      type: Boolean,
      default: true,
    },
    show_matrix3: {
      type: Boolean,
      default: true,
    },
    show_matrix4: {
      type: Boolean,
      default: true,
    },
    show_study_plan: {
      type: Boolean,
      default: true,
    },
    show_curricular_mesh: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      background_color: "var(--header-color)",
      position: "bottom-right",
      fab_actions: [
        {
          name: "cache",
          icon: "cached",
        },
        {
          name: "alertMe",
          icon: "add_alert",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      cycles: names.CYCLES,
    }),
  },
  methods: {
    goToEgressProfile() {
      this.$router.push({
        name: "EgressProfileView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix1() {
      this.$router.push({
        name: "Matrix1View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix2() {
      this.$router.push({
        name: "Matrix2View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix3() {
      this.$router.push({
        name: "Matrix3View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix4() {
      this.$router.push({
        name: "Matrix4View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToStudyPlan() {
      this.$router.push({
        name: "StudyPlanView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToCurricularMesh() {
      this.$router.push({
        name: "CurricularMeshView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
  },
  created() {
    if (this.cycles.length == 0) {
      this.$store.dispatch(names.FETCH_CYCLES);
    }
    this.fab_actions = [];
    if (this.show_egress_profile) {
      this.fab_actions.push({
        name: "goToEgressProfile",
        icon: "school",
        tooltip: String(
          this.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")
        ),
        color: "var(--secondary-color)",
      });
    }
    if (this.show_matrix1) {
      this.fab_actions.push({
        name: "goToMatrix1",
        icon: "view_list",
        tooltip: this.$getVisibleNames("manual.matriz_1", false, "Matriz 1"),
        color: "var(--secondary-color)",
      });
    }
    if (this.show_matrix2) {
      this.fab_actions.push({
        name: "goToMatrix2",
        icon: "view_module",
        tooltip: this.$getVisibleNames("manual.matriz_2", false, "Matriz 2"),
        color: "var(--secondary-color)",
      });
    }
    if (this.show_matrix3) {
      this.fab_actions.push({
        name: "goToMatrix3",
        icon: "view_quilt",
        tooltip: this.$getVisibleNames("manual.matriz_3", false, "Matriz 3"),
        color: "var(--secondary-color)",
      });
    }
    if (
      this.show_matrix4 &&
      ((this.institution && this.institution.internal_use_id == 'duoc_uc') ||
        this.$debug_change_duoc)
    ) {
      this.fab_actions.push({
        name: "goToMatrix4",
        icon: "view_comfy",
        tooltip: this.$getVisibleNames("manual.matriz_4", false, "Matriz 4"),
        color: "var(--secondary-color)",
      });
    }
    if (this.show_study_plan) {
      this.fab_actions.push({
        name: "goToStudyPlan",
        icon: "public",
        tooltip: "Plan de Estudio",
        color: "var(--secondary-color)",
      });
    }
    if (this.show_curricular_mesh) {
      this.fab_actions.push({
        name: "goToCurricularMesh",
        icon: "dashboard",
        tooltip: "Malla Curricular",
        color: "var(--secondary-color)",
      });
    }
  },
};
</script>

<style scoped>
.fab {
  background-color: var(--header-color);
}
</style>